export const environment = {
    production: false,
    storagePrefix: 'woonig-dev-',
    firebase: {
        apiKey: 'AIzaSyA9giZIO9Rg6zgDsqBIad3Unq-OAN43UKw',
        authDomain: 'woonig-dev.firebaseapp.com',
        databaseURL: 'https://woonig-dev.firebaseio.com',
        projectId: 'woonig-dev',
        storageBucket: 'woonig-dev.appspot.com',
        messagingSenderId: '492800761410',
        languageDocId: 'HbH3aZPcKE2M2ecsrYcc',
    },
    region: 'us-central1',
    apiBase: 'https://woonig-dev.appspot.com/apiv1/',
    webExposeUrl: `https://woonig-expose.web.app/namespace/:namespaceId?env=dev`,
    reCaptcha: {
        webKey: '6Ld9RNQUAAAAABt7NWmxiNK4oMuW7O-X-Oxp6xp2',
    },
    googleMapsApiKey: 'AIzaSyDeXtXJF1rUARg0_K_JVND2e12NyRBCr5E',
    store_link: {
        ios: 'itms-apps://apps.apple.com/ch/app/woonig-mieter-stwe/id1476238924',
        android: 'https://play.google.com/store/apps/details?id=ch.woonig.tenant',
    },
};
